import Axios from './Axios';

class FacilitiesService {
  index(orgId) {
    return Axios({
      method: 'GET',
      url: 'facility?orgId=' + orgId
    });
  }
  allFacilities(orgId) {
    return Axios({
      method: 'GET',
      url: 'all-facilities?organization_id=' + orgId,
    });
  }
  store(facility) {
    return Axios({
      method: 'POST',
      url: 'facility',
      data: facility
    });
  }
  update(facility) {
    return Axios({
      method: 'PATCH',
      url: 'facility/' + facility.id,
      data: facility
    });
  }
  delete(facility) {
    return Axios({
      method: 'DELETE',
      url: 'facility/' + facility.id
    });
  }
  organizationFacility(organizationFacility) {
    return Axios({
      method: "POST",
      url: 'organization_facility',
      data: organizationFacility
    })
  }
  managerFacilities(token = null) {
    if (token) {
      return Axios({
        method: "GET",
        url: "user-managed-facilities",
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
    }
    return Axios({
      method: "GET",
      url: 'user-managed-facilities',
    })
  }
  facilityOrganizations(facility) {
    return Axios({
      method: "GET",
      url: 'facility-organizations/' + facility.id,
    })
  }
  facilityBlacklist(facility_id) {
    return Axios({
      method: "GET",
      url: 'facility/' + facility_id + '/blacklist',
    })
  }
}

export default new FacilitiesService()
