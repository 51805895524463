<template>
  <v-card outlined class="mt-n3" tile>
    <v-data-table :headers="headers" :items="watchmenWithIndex" sort-by="name" class="elevation-1"
      loading-text="Loading... Please wait">
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="10" class="mt-5">
            <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
              <v-icon class="mr-2 mt-n1" color="info darken-1">mdi-account-tie</v-icon>
              Security Officers Schedule
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <div align="right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green" dark class="text-capitalize my-auto ml-1" elevation="3"
                  @click="scheduleWatchmanModal" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-plus</v-icon>
                  <span> New Schedule </span>
                </v-btn>
              </template>
              <span>New Schedule</span>
            </v-tooltip>
          </div>
        </v-toolbar>
        <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>
      </template>

      <template v-slot:[`item.applicant.company_logo_url`]="{ item }">
        <v-img max-height="64" max-width="64" :src="item.applicant.company_logo_url" class="my-3 mr-auto" rounded
          outlined :lazy-src="item.applicant.company_logo_url"></v-img>
      </template>

      <template v-slot:no-data>
        <a class="info--text text--darken-1">
          No security officers on schedule.
          Go to organisation, view details and create new user with security role
        </a>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <a color="primary" class="mx-1 primary--text text--darken-1 text-decoration-underline" dark outlined text
            @click="editItem(item)">
            Go To Details
          </a>

        </div>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="showEditWatchman(item)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.delete`]="{ item }">
        <v-btn color="error" class="mx-1" fab x-small dark elevation="0" @click="deleteWatchman(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

    </v-data-table>
    <v-dialog v-model="showWatchmanModal" max-width="600">
      <v-card>
        <v-card-title class="grey--text text-h5">
          Schedule
        </v-card-title>

        <v-tooltip bottom class="tooltip-right">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info ma-2" icon dark class="text-capitalize my-auto ml-1" elevation="0" @click="showInfoModal"
              v-bind="attrs" v-on="on">
              <span class="material-icons">info</span>
            </v-btn>
          </template>
          <span>View Description</span>
        </v-tooltip>

        <v-divider></v-divider>

        <v-card-text class="mb-0 pb-0">
          <v-form class="pt-7 pb-0">
            <v-autocomplete dense v-model="watchman.usr_id" :items="watchmenList" item-value="id"
              item-text="attributes.name" label="Security Officer" :error-messages="apiErrors.usr_id" outlined>
            </v-autocomplete>

            <v-autocomplete dense v-model="watchman.fcl_id" :items="facilitiesList" item-value="id"
              item-text="attributes.facility" label="Facility" @change="changeFacilityID"
              :error-messages="apiErrors.fcl_id" outlined>
            </v-autocomplete>

            <v-row>
              <v-col>
                <v-select dense v-model="watchman.entry_id" :items="facility_entry_points" item-text="entry_point_name"
                  item-value="id" label="Passage" :error-messages="apiErrors.entry_id" outlined
                  @change="entryID = watchman.entry_id">
                </v-select>
              </v-col>
              <v-col>
                <v-text-field dense v-model="passage_type" label="Passage Type" readonly outlined></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-text-field dense v-model="fromDate" label="From Date" v-bind="attrs" v-on="{ ...menu }"
                      :error-messages="apiErrors.from_date" outlined>
                    </v-text-field>
                  </template>

                  <v-date-picker v-model="fromDate" color="primary" header-color="primary"></v-date-picker>

                </v-menu>
              </v-col>

              <v-col>
                <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-text-field dense v-model="toDate" label="To Date" v-bind="attrs" v-on="{ ...menu }"
                      :error-messages="apiErrors.thru_date" outlined>
                    </v-text-field>

                  </template>

                  <v-date-picker v-model="toDate" color="primary" header-color="primary" :min-date="fromDate">
                  </v-date-picker>

                </v-menu>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>

          <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="editWatchman" color="green darken-1 white--text" @click="saveWatchmanUpdates"
            class="text-capitalize mx-1">
            Update
          </v-btn>

          <v-btn v-else color="green darken-1 white--text" @click="saveWatchman" class="text-capitalize mx-1">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showInfo" :z-index="9999"
      :style="{ position: 'absolute', top: '0', right: '0', 'z-index': '9999' }" max-width="400">
      <v-card>
        <v-card-title>
          When there's no security to sign, create a user with security role
        </v-card-title>
        <v-card-text>
          <p v-if="currentStep === 1">
            Click organisation menu and click view details button on specific organisation
          </p>
          <p v-if="currentStep === 2">Click add user button</p>
          <p v-if="currentStep === 3">Create new user with security role</p>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="currentStep > 1" color="info" x-small @click="currentStep--">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="currentStep < 3" color="info" x-small @click="currentStep++">Next</v-btn>
          <v-btn color="info" x-small @click="showInfo = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import FacilitiesService from "../../services/facilities.service";
import EntriesService from "../../services/entries.service";
import WatchmenService from "../../services/watchmen.service";
import Watchman from "../../models/watchman";

export default {
  components: {},

  data: () => ({
    name: "Watchmen",
    loading: true,
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    defaultItem: {
      name: "",
      attachment: "",
      attachment_file: [],
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    search: '',
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "index",
      },
      {
        text: "Security Officer",
        value: "attributes.name",
      },
      {
        text: "Facility",
        value: "relationship.schedule.attributes.facility.facility",
      },
      {
        text: "Passage",
        value: "relationship.schedule.relationships.passage.attributes.passage",
      },
      {
        text: "Type",
        value: "relationship.schedule.relationships.passage.relationships.type.attributes.type",
      },
      {
        text: "From",
        value: "relationship.schedule.attributes.from",
      },
      {
        text: "To",
        value: "relationship.schedule.attributes.to",
      },
      {
        text: "Edit",
        align: "center",
        value: "edit",
        sortable: false,
      },
      {
        text: "Delete",
        align: "center",
        value: "delete",
        sortable: false,
      },
    ],
    watchman: new Watchman(),
    passage_type: null,
    showWatchmanModal: false,
    apiErrors: [],
    editWatchman: false,
    watchmenList: [],
    facilitiesList: [],
    facility_entry_points: [],
    facilityID: '',
    closeOnContentClick: true,
    fromDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    toDate: '',
    entryID: '',
    // Info modal functionality variables
    showInfo: false,
    currentStep: 1
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    watchmenWithIndex() {
      return this.watchmenList.map(
        (items, index) => ({
          ...items,
          index: index + 1,
        })
      )
    },

  },

  watch: {
    entryID(newEntryID, oldEntryID) {
      if (newEntryID !== oldEntryID) {
        this.extractPassageType();
      }
    },

    facilityID(newFacilityID, oldFacilityID) {
      if (newFacilityID !== oldFacilityID) {
        this.getEntryPoints();
      }
    }
  },

  created() {

    this.$nextTick(() => {
      this.getWatchmen();
      this.getFacilities();
    });

  },

  methods: {
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    getWatchmen() {
      WatchmenService.index().then(
        (response) => {
          if (response.status === 200) {
            this.watchmenList = response.data.data;
            this.loading = false;
          } else {
            this.watchmenList = [];
            console.log(response);
          }
        },
        (error) => {
          this.watchmenList = [];
          console.log(error);
          if (error.response.status === 401) {
            this.handleLogout();
          }
        }
      );
    },

    getFacilities() {
      let orgId = this.org?.id;
      FacilitiesService.index(orgId).then(
        (response) => {
          if (response.status === 200) {
            this.facilitiesList = response.data.data;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    showEditWatchman(item) {
      console.log("item from show edit");
      console.log(item);
      this.watchman.usr_id = item.id

      if (item.relationship.schedule == null) {
        this.scheduleWatchmanModal();
        return;
      }

      this.getWatchmen();
      this.getFacilities();

      // Schedule id
      this.watchman.id = item.relationship.schedule.id

      this.watchman.fcl_id = (item.relationship.schedule.attributes.facility.id).toString();
      this.facilityID = this.watchman.fcl_id;
      this.watchman.from_date = item.relationship.schedule.attributes.from;
      this.watchman.thru_date = item.relationship.schedule.attributes.to;

      this.watchman.entry_id = item.relationship.schedule.attributes.entry_point ? (item.relationship.schedule.attributes.entry_point.id).toString() : '';

      this.fromDate = (new Date(new Date(this.watchman.from_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      if (this.watchman.thru_date) {
        this.toDate = (new Date(new Date(this.watchman.thru_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      }

      this.editWatchman = true;
      this.showWatchmanModal = true;
      this.entryID = this.watchman.entry_id;

      let schedule = item.relationship.schedule;
      this.passage_type = schedule ? schedule.relationships.passage.attributes.type : null

      this.getEntryPoints();
      this.extractPassageType(this.watchman.entry_id);

    },

    deleteWatchman(item) {
      let schedule = this.watchman.id = item.relationship.schedule;
      WatchmenService.delete(schedule).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            this.$store.dispatch("alert/success", "Deleted Watchman scheduled successfully");
            this.getWatchmen();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          if (error.response.status === 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );

    },

    scheduleWatchmanModal() {
      this.getWatchmen();
      this.getFacilities();
      this.showWatchmanModal = true;
    },

    showInfoModal() {
      this.showInfo = true;
    },

    closeModal() {
      this.showWatchmanModal = false;
      this.editWatchman = false;
      this.facility_entry_points = [];
      this.fromDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.toDate = '';
      this.passage_type = null;
      this.watchman = new Watchman();
    },

    saveWatchman() {
      this.watchman.from_date = this.fromDate;
      this.watchman.thru_date = this.toDate;
      WatchmenService.store(this.watchman).then(
        (response) => {
          if (response.status === 200) {
            this.watchmenList.push(this.editedItem);
            this.$store.dispatch(
              "alert/success",
              "Item created successfully"
            );
            this.loading = false;
            this.getWatchmen();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", "Created watchman schedule successfully");
            this.getWatchmen();
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch(
              "alert/error",
              error.response.data.message
            );

            this.apiErrors = error.response.data.errors
            this.loading = false;
            this.getWatchmen();
          }
          if (error.response.status === 401) {
            this.handleLogout();
            this.loading = false;
            this.getWatchmen();
          }

          if (error.response.status === 404) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.closeModal();
          }

        }
      );

    },

    saveWatchmanUpdates() {
      this.watchman.from_date = this.fromDate;
      this.watchman.thru_date = this.toDate;
      WatchmenService.update(this.watchman).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch("alert/success", "Updated Watchman schedule successfully");
            this.loading = false;
            this.getWatchmen();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.getWatchmen();
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch(
              "alert/error",
              error.response.data.message
            );
            this.loading = false;
            this.getWatchmen();
          }
          if (error.response.status === 401) {
            this.handleLogout();
            this.loading = false;
            this.getWatchmen();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );

    },

    getEntryPoints() {
      EntriesService.facilityEntryPoints(this.watchman.fcl_id).then(
        entries => {
          this.facility_entry_points = entries.data.data
          console.log(this.facility_entry_points)
        },
        fail => {
          console.log(fail)
        }
      );
    },

    changeFacilityID() {
      this.facilityID = this.watchman.fcl_id
      this.getEntryPoints()
    },

    extractPassageType() {
      let points = this.facility_entry_points;
      if ((this.facility_entry_points).length !== 0) {
        points.forEach(entry => {
          if (entry.id === this.entryID) {
            this.passage_type = entry.relationships.type.attributes.type
          }
        });
      }
    }
  },

  mounted() {
    // Set the nearest positioned ancestor to the body element
    document.body.style.position = 'relative';
  }
};
</script>
