// Data sevice
import Axios from './Axios';
class WatchmenService {

    index() {
        return Axios({
            method : "GET",
            url : 'watchmen',
        })
    }
    orgIndex(id) {
        return Axios({
            method : "GET",
            url : 'watchmen/'+id,
        })
    }
    store(item) {
        return Axios({
            method : "POST",
            url : 'watchmen', 
            data : item
        })
    }
    update(item) {
        return Axios({
            method : "PATCH",
            url : 'watchmen/'+item.id, 
            data : item
        })
    }
    delete(item) {
        return Axios({
            method : "DELETE",
            url : 'watchmen/'+item.id,
        })
    }
}

export default new WatchmenService()
