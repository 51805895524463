// Data sevice
import Axios from './Axios'
class EntriesService {

    facilityEntryPoints(facility_id) {
        return Axios({
            method : "GET",
            url : 'facility-entry-points/' + facility_id,
        });
    }

    index() {
        return Axios({
            method : "GET",
            url : 'facility_entry'
        })
    }
    orgIndex(id) {
        return Axios({
            method : "GET",
            url : 'facility_entry/' + id
        })
    }
    store(item) {
        return Axios({
            method : "POST",
            url : 'facility_entry', 
            data : item
        })
    }
    update(item) {
        return Axios({
            method : "PUT",
            url : 'facility_entry/' + item.id, 
            data : item
        })
    }
    delete(item) {
        return Axios({
            method : "DELETE",
            url : 'facility_entry/' + item.id
        })
    }
}

export default new EntriesService()
